<template>
  <div id="http-wrapper">
    <img class="logo" :src="'./media/mapplico_logo_transparent.png'" alt="" />
    <div id="error">
      <h1>404</h1>
    </div>
    <p class="subtitle" v-if="!storeErr">Aradığınız Sayfa Bulunamadı !</p>
    <p class="storeMsg" v-if="storeErr">
      Bu mağaza sistemde kayıtlı değil. Lütfen satış temsilciniz ile iletişime
      geçiniz.
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      storeErr: false,
    };
  },
  created() {
    if (this.$route.query.store) {
      this.storeErr = true;
      this.$router.push('/notfound')
    } else {
      this.storeErr = false;
    }
  },
};
</script>